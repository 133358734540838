import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Label, FormFeedback, FormText, FormError } from '../form';
import DateInput from '../form/DateInput';
import FieldTooltip from '../tooltip/FieldTooltip';

const DateField = (props) => {
  const {
    required,
    disabled,
    field,
    label,
    feedback,
    helptext,
    form: { setFieldValue, setFieldTouched, touched, errors },
    options,
    placeholder,
    fromFormat,
    toFormat,
    transform,
    tooltipLabel,
    intl: { formatMessage },
    ...restProps
  } = props;

  // Cleave cannot handle null https://github.com/nosir/cleave.js/issues/400
  const val = field.value === null ? undefined : field.value;

  return (
    <>
      {label && (
        <Label htmlFor={field.name} required={required}>
          <FormattedMessage id={label} defaultMessage={label} />
        </Label>
      )}
      <DateInput
        {...restProps}
        {...field}
        onBlur={(val) => { setFieldTouched(field.name); setFieldValue(field.name, val); }}
        value={val}
        placeholder={placeholder}
        disabled={disabled}
        options={options}
        fromFormat={fromFormat}
        toFormat={toFormat}
        touched={touched[field.name]}
        error={errors[field.name]}
        transform={transform}
      />
      {feedback && <FormFeedback>{feedback}</FormFeedback>}
      {helptext && <FormText>{helptext}</FormText>}
      {touched[field.name] && errors[field.name] && (
        <FormError data-testid="date-field-error">
          {typeof errors[field.name] === 'string' && <FormattedMessage id={errors[field.name]} />}
          {typeof errors[field.name] !== 'string' && errors[field.name]}
        </FormError>
      )}
      {tooltipLabel && (
        <FieldTooltip
          placement="right"
          content={formatMessage({ id: tooltipLabel })}
          iconColor="gray"
          iconSize="sm"
          right="-112px"
          minWidth="150px"
        />
      )}
    </>
  );
};

DateField.defaultProps = {
  disabled: false,
};

DateField.propTypes = {
  disabled: PropTypes.bool,
  feedback: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func,
  }),
  helptext: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  label: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  fromFormat: PropTypes.string,
  toFormat: PropTypes.string,
  tooltipLabel: PropTypes.string,
  transform: PropTypes.func,
};

export default injectIntl(DateField);
